export const LineStatusMap = {
	"STATUS_CREATING": "创建中",
	"STATUS_CREATE_FAILED": "创建失败",
	"STATUS_RUNNING": "运行中",
	"STATUS_DUE": "已过期",
	"STATUS_DISABLED": "不可用",
	"STATUS_DELETED": "已删除",
	"STATUS_DELETED_IN_ADVANCE": "提前删除",
}

export const ROUTER_STATUS_MAP = {
	0: '空闲',
	1: '使用中'
}

const payTypeMap = {
	BALANCE: {
		type: 'amount',
		text: '余额支付'
	},
	WECHAT_NATIVE: {
		type: 'wechatpay_native',
		text: '微信扫码支付'
	},
	ALIPAY: {
		type: 'alipay_page',
		text: '支付宝扫码支付'
	},
}

export const PAY_TYPE_MAP = {
	...payTypeMap,

	getInstanceByType(type) {
		const exist = Object.getOwnPropertyNames(payTypeMap).find(key => payTypeMap[key].type === type)
		return exist && payTypeMap[exist]
	}
}

export const IpRegionMap = {
	"uk-london": "伦敦",
	"uae-dubai": "迪拜",
	"us-ws": "华盛顿",
	"hk": "香港",
	"sg": "新加坡",
	"ge-fra": "法兰克福",
	"tw-tp": "台北",
	"us-ca": "洛杉矶",
	"jpn-tky": "东京",
	"idn-jakarta": "雅加达",
	"vn-sng": "胡志明",
	"th-bkk": "曼谷",
	"ph-mnl": "马尼拉",
	"kr-seoul": "首尔",
	"sg-malaysia": "马来西亚",
	"cn-gd": "广州",
	"vn-dyn": "越南动态",
	"bra-saopaulo": "圣保罗",
	"afr-nigeria": "尼日利亚",
	"my-kl": "吉隆坡",
	"us-va-st": "弗吉尼亚",
	"us-ny-st": "纽约",
	"us-ny": "纽约",
	"us-va": "弗吉尼亚",
	"vn-hn": "河内",
	"rus-mosc": "莫斯科",
	"sa": "沙特阿拉伯",
	"ind-mumbai": "孟买",
	"tr": "伊斯坦布尔",
	"za": "约翰内斯堡",
	"es": "马德里",
	"fr": "巴黎",
	"it": "罗马",
	"au": "悉尼",
	"ca": "多伦多",
	"ca-trt": "多伦多",
	"au-syd": "悉尼",
	"fr-par": "巴黎"
}

export const ProductTypeMap = {
	"path-golden": "金牌线路",
	"path-goldenPlus": "金牌高清线路",
	"path-goldenPro": "金牌超清线路",
	"path-silvery": "银牌线路",
	"path-platinum": "铂金线路",
	"path-platinumPlus": "铂金高清线路",
	"path-platinumPro": "铂金超清线路",
	"path-zircon": "视频线路",
	"path-zirconPremium": "视频精品线路",
	"universal": "通用版",
	"matrix": "矩阵版",
	"live": "直播版",
	"phone-advanced": "精品版",
	"phone-shared": "共享版",
	"phone-exclusive": "独享版",
	"phone-noip": "无网版",
	"phone-social": "天晴定制版",
	"phone-matrix": "矩阵1:4版",
	"phone-ip": "精品IP版",
	"phone-matrix8": "矩阵1:8版",
	"phone-matrix16": "矩阵1:16版",
	"path-titanium":"宅联线路"


}

export const unknownQuantityLines = ['path-golden', 'path-goldenPlus', 'path-goldenPro', 'path-silvery']

export const ProductStockMap = {
	'path-golden': {
		name: 'Golden',
		quantity: 0,
		have: false
	},
	'path-goldenPlus': {
		name: 'GoldenPlus',
		quantity: 0,
		have: false
	},
	'path-goldenPro': {
		name: 'GoldenPro',
		quantity: 0,
		have: false
	},
	'path-silvery': {
		name: 'Silvery',
		quantity: 0,
		have: false
	},
	'path-platinum': {
		name: 'Platinum',
		quantity: 0,
		have: false
	},
	'path-platinumPlus': {
		name: 'PlatinumPlus',
		quantity: 0,
		have: false
	},
	'path-platinumPro': {
		name: 'PlatinumPro',
		quantity: 0,
		have: false
	},
	'path-zircon': {
		name: 'Zircon',
		quantity: 0,
		have: false
	},
	'path-zirconPremium': {
		name: 'ZirconPremium',
		quantity: 0,
		have: false
	},
	'path-titanium': {
		name: 'Titanium',
		quantity: 0,
		have: false
	},
}

export const AutoRenewMap = {
	0: "不开启",
	30: "按月",
	90: "按季度",
	180: "按半年",
	360: "按年",
}

export const TiktokRegionMap = {
	"TW":"中国台湾",
	"SG":"新加坡",
	"JP":"日本",
	"ID":"印尼",
	"VN":"越南",
	"TH":"泰国",
	"PH":"菲律宾",
	"KR":"韩国",
	"MY":"马来西亚",
	"US":"美国",
	"BR":"巴西",
	"GB":"英国",
	"DE":"德国",
	"AE":"阿联酋",
	"NG":"尼日利亚",
}

export const LanguageMap = {
	en: '英语',
	zh: '中文'
}

export const TkDestRegion = {
	"uk-london": "英国",
	"sg": "新加坡",
	"ge-fra": "德国",
	"tw-tp": "台湾",
	"us-ca": "美国",
	"jpn-tky": "日本",
	"idn-jakarta": "印度尼西亚",
	"vn-sng": "越南",
	"th-bkk": "泰国",
	"ph-mnl": "菲律宾",
	"kr-seoul": "韩国",
	"my-kl": "马来西亚",
}

export function isZirconLine(lineType) {
	const zirconType = ['path-zircon', 'path-zirconPremium']
	return zirconType.includes(lineType)
}