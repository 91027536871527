<template>
  <div class="create-wrap">
    <div class="bread-wrap">
      <span class="bread-title">直播快/创建线路</span>
    </div>
    <div class="content">
      <div class="content-right">
        <div class="ffxx">
          <p class="title-wrap">
            <i class="iconfont icon-bill-detail titleicon"></i><span class="title">付费信息</span>
          </p>
          <div class="ff-detail">
            <span class="dtcontent">线路类型</span><span class="dttext">{{ detailform.guige }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtcontent">目标区域</span><span class="dttext">{{ detailform.region }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtcontent">购买数量</span><span class="dttext">{{ detailform.num }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtcontent">购买时长</span><span class="dttext">{{ detailform.sc }}</span>
          </div>
          <div class="ff-detail">
            <span class="dtdiscount">优惠金额</span><span class="dtdiscount">{{ detailform.discount }} 元</span>
          </div>
          <div class="single-line"></div>
          <div class="ff-detail">
            <span class="dtprice">合计费用</span><span class="dtamount">{{ detailform.total }} 元</span>
          </div>
          <el-radio-group v-model="detailform.PayType" class="payradio">
            <el-radio :disabled="balanceNotAvailable"
                      class="itemradio"
                      label="amount">余额支付(可用:{{ balance }})
            </el-radio>
            <el-radio class="itemradio" label="wechatpay_native" disabled>
              <i class="iconfont icon-weixinzhifu"></i>微信支付
            </el-radio>
            <el-radio class="itemradio"
                      label="alipay_page">
              <div class="itemradio-content">
                <img class="icon-alipay" src="@/assets/icon-alipay.png"/>支付宝支付
              </div>
            </el-radio>
          </el-radio-group>
          <el-button v-if="!submitDisable"
                     :disabled="submitDisable"
                     class="paybtn"
                     size="small"
                     type="primary"
                     @click="navorderconfirm">提交订单
          </el-button>
          <el-button v-else
                     :disabled="submitDisable"
                     class="paybtn2"
                     size="small"
                     type="primary"
                     @click="navorderconfirm">提交订单
          </el-button>
        </div>
      </div>
      <div class="content-left">
        <div class="diyu">
          <p class="title-wrap">
            <i class="iconfont icon-aim-region titleicon"></i><span class="title">区域选择</span>
          </p>

          <div class="tabs">
            <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
              <el-tab-pane name="china">
                <span slot="label" style="font-size: 16px">国内</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in ChinaRegions"
                      :key="index"
                      :class="[{'select-content-active': regionselect == item.regionId },'select-content',]"
                      @click="selectdy(item.regionId, item)"
                    >
                      {{ item.name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="southeastasia">
                <span slot="label" style="font-size: 16px">亚太</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in SoutheastAsiaRegions"
                      :key="index"
                      :class="[{'select-content-active': regionselect == item.regionId },'select-content',]"
                      @click="selectdy(item.regionId, item)"
                    >
                      {{ item.name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="america">
                <span slot="label" style="font-size: 16px">美洲</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in AmericaRegions"
                      :key="index"
                      :class="[{'select-content-active': regionselect == item.regionId },'select-content',]"
                      @click="selectdy(item.regionId, item)"
                    >
                      {{ item.name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="europe">
                <span slot="label" style="font-size: 16px">欧洲</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in EuropeRegions"
                      :key="index"
                      :class="[{'select-content-active': regionselect == item.regionId },'select-content',]"
                      @click="selectdy(item.regionId, item)"
                    >
                      {{ item.name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="middleeast">
                <span slot="label" style="font-size: 16px">中东</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in MiddleEastRegions"
                      :key="index"
                      :class="[{ 'select-content-active': regionselect == item.regionId },'select-content',]"
                      @click="selectdy(item.regionId, item)"
                    >
                      {{ item.name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="africa">
                <span slot="label" style="font-size: 16px">非洲</span>
                <div class="selectdiyu">
                  <div class="select-1">
                    <p
                      v-for="(item, index) in AfricaRegions"
                      :key="index"
                      :class="[{'select-content-active': regionselect == item.regionId }, 'select-content',]"
                      @click="selectdy(item.regionId, item)"
                    >
                      {{ item.name2 }}
                    </p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="guige">
          <!-- 标题 -->
          <p class="title-wrap">
            <i class="iconfont icon-phone-spec titleicon"></i>
            <span class="title">线路类型</span>
          </p>

          <!-- 按钮切换 -->
          <div class="button-group">
            <button
              v-for="(group, idx) in lineGroups"
              :key="idx"
              :class="{ active: selectedGroup === group.group }"
              @click="changeGroup(group.group)"
            >
              {{ group.group }}
            </button>
          </div>

          <!-- 线路展示 -->
          <div class="select-guige">
            <div
              v-for="(item, index) in selectedLines"
              :key="index"
              :class="['selectcon', { 'selectcon-active': guige === item.Id }]"
              @click="selectguige(item.Id, item)"
            >
              <!-- 判断是否禁用 -->
              <p v-if="guigeDisable(item.Id)" class="ggtp2">{{ item.Name }}</p>
              <p v-else :class="['ggtp3', { ggtp: guige === item.Id }]">{{ item.Name }}</p>

              <!-- 分割线 -->
              <div v-if="guigeDisable(item.Id)" class="hori-line2"></div>
              <div v-else class="hori-line"></div>

              <!-- 描述信息 -->
              <div v-if="guigeDisable(item.Id)">
                <p v-for="(desc, idx) in item.desc" :key="idx" class="ggdes2">{{ desc }}</p>
                <p class="ggdessource" style="text-align: center">资源补充中...</p>
              </div>
              <div v-else>
                <p
                  v-for="(desc, idx) in item.desc"
                  :key="idx"
                  :class="['ggdes3', { ggdes: guige === item.Id }]"
                  v-html="hightlight(desc)"
                ></p>
              </div>
            </div>
          </div>
        </div>
        <div class="notes" v-if="showNotes">
          * &nbsp;{{ lineNotes }}
        </div>
        <div class="scxz">
          <p class="title-wrap">
            <i class="iconfont icon-select-duration titleicon"></i><span class="title">购买时长</span>
          </p>
          <div class="select-sc">
            <div class="select-item">
              <div v-for="item in buyTime"
                   :class="['day', { 'sc-active': sc == item.chargeId, 'sc-disable': buyTimeDisable(item.chargeId) }]"
                   @click="selectsc(item.chargeId)" :key="item.chargeId">
                <span>{{ item.text }}</span>
                <span v-if="item.discount"
                      class="sc-discount">({{ item.discount }})</span>
              </div>
            </div>
          </div>
          <div class="auto-renew">
            <span class="auto-renew-title">自动续费</span>
            <el-switch v-model="payform.AutoRenewSwitch"
                       active-color="rgba(60, 100, 235, 1)"></el-switch>
            <span v-if="payform.AutoRenewSwitch"
                  class="auto-renew-title title2">自动续费时长</span>
            <el-select v-if="payform.AutoRenewSwitch"
                       v-model="payform.AutoRenew"
                       change="autoRenewChange"
                       class="auto-renew-select"
                       size="small">
              <el-option v-for="item in autoRenewOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="gmsl">
          <p class="title-wrap">
            <i class="iconfont icon-buycount titleicon"></i><span class="title">购买数量</span>
          </p>
          <div class="select-number">
            <el-input-number v-model="payform.BuyCount"
                             :max="productMaxQuantity"
                             :min="1"
                             size="small"
                             @change="selectnum"></el-input-number>
          </div>
        </div>
        <div class="fzxz">
          <p class="title-wrap">
            <i class="iconfont icon-set-group titleicon"></i><span class="title">设置分组</span>
          </p>
          <div class="select-fz">
            <span class="fztext">名称</span>
            <el-input v-model="payform.Name"
                      placeholder="请填写线路名称"
                      size="small"
                      style="width: 180px">
            </el-input>
          </div>
          <div class="select-fz">
            <span class="fztext">分组</span>
            <el-select v-model="payform.GroupId"
                       filterable
                       placeholder="请选择分组"
                       size="small">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getToken} from "@/utils/auth";
import navigation from "@/utils/navigation";
import {lineType, specifications} from "@/utils/optionList";
import {mapGetters} from "vuex";
import { isZirconLine, ProductStockMap, unknownQuantityLines } from '@/utils/constants';

export default {
  data() {
    return {
      activeName: 'china',
      regionselect: 0,
      regionitem: {
        Prices: [],
      },
      guige: "0",
      guigeitem: {
        Prices: [],
      },
      buyTime: [
        {
          chargeId: 30,
          text: '月付',
        },
        {
          chargeId: 90,
          text: '季付',
          discount: '97折'
        },
        {
          chargeId: 180,
          text: '半年付',
          discount: '95折',
        },
        {
          chargeId: 360,
          text: '年付',
          discount: '9折'
        },
      ],
      sc: 30,
      specifications,
      linetype: [],
      discount: 1.0,
      Regions: [],
      ChinaRegions: [],
      SoutheastAsiaRegions: [],
      AmericaRegions: [],
      EuropeRegions: [],
      MiddleEastRegions: [],
      AfricaRegions: [],
      Products: [],
      productDesc: [],
      lineGroups: lineType, // 分组数据
      selectedGroup: lineType[0].group, // 默认展示第一个分组
      options: [],
      min: 1,
      detailform: {
        region: "",
        guige: "",
        num: 1,
        sc: "1月",
        discount: 0,
        total: 0,
        PayType: "amount",
      },
      payform: {
        ProductId: "",
        BuyCount: 1,
        ChargeId: 30,
        IpRegionId: "",
        GroupId: '',
        Name: "",
        AutoRenew: 30,
        AutoRenewSwitch: true,

      },
      balanceNotAvailable: false,
      productStockMap: ProductStockMap,
      submitDisable: false,
      balance: 0.01,
      autoRenewOptions: [
        // { value: 0, label: '不开启' },
        {value: 30, label: '按月'},
        {value: 90, label: '按季度'},
        {value: 180, label: '按半年'},
        {value: 360, label: '按年'},
      ]
    };
  },
  watch: {
    'payform.IpRegionId': {
      handler: function (val) {
        this.$store
            .dispatch("line/regionstocklist", {regionId: this.payform.IpRegionId})
            .then((res) => {
              if (Object.keys(res).length !== 0) {
                Object.keys(this.productStockMap).forEach(key => {
                    const stockInfo = this.productStockMap[key]
                    const quantity = res[stockInfo.name]
                    stockInfo.quantity = unknownQuantityLines.includes(key) && quantity ? 999 : quantity
                    stockInfo.have = !!quantity
                })
                this.submitDisable = this.currentGuigeDisable
              }
            });
      },
    },
    payform: {
      handler: function (val) {
        if (this.payform.ProductId == "" || this.payform.IpRegionId == "") {
          return;
        }
        this.$store
            .dispatch("line/getpathprice", {...this.payform})
            .then((res) => {
              // console.log("line get price: " + JSON.stringify(res));
              if (res.RetCode == 0) {
                this.detailform.discount = res.Discounts / 100;
                this.detailform.total = res.TotalAmount / 100;//分转换为元
                this.payTypeControl(this.balance >= this.detailform.total)
              }
            });
      },
      deep: true,
    },
    balance(newBalance) {
      // 监听余额，如果余额有变动，且当前有价格，则进行对比，看余额是否足够
      const { total } = this.detailform
      this.payTypeControl(newBalance >= total)
    }
  },
  methods: {
    payTypeControl(useBalance = false) {
      if (useBalance) {
        this.balanceNotAvailable = false
        this.detailform.PayType = "amount"
      } else {
        this.balanceNotAvailable = true;
        this.detailform.PayType = "wechatpay_native";
      }
    },

    buyTimeDisable(chargeId) {
      const selectedType = this.guigeitem
      if (!selectedType || !selectedType.Id) {
        return false
      }
      return false
      // 放开铂金线路三个月起购的限制
      // const isPlatinum = /Platinum/i.test(selectedType.Id)
      // return isPlatinum && chargeId === 30
    },

    // 调用接口的方法
    fetchAutoRenewSwitchStatus() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
          if (res.AutomaticRenewal) {
            this.payform.AutoRenewSwitch = !res.AutomaticRenewal
          } else {
            this.payform.AutoRenewSwitch = !res.AutomaticRenewal
          }
        }
      });
    },

  
    initData() {
      // 初始化线路数据并分割描述
      this.Products = this.lineGroups.flatMap(group =>
        group.list.map(item => {
          item.desc = item.Description.split("&");
          return item;
        })
      );
      // 默认选中当前分组的第一条线路
      const defaultLine = this.selectedLines[0];
      if (defaultLine) this.selectguige(defaultLine.Id, defaultLine);
    },
    changeGroup(group) {
      this.selectedGroup = group;
      const defaultLine = this.selectedLines[0];
      if (defaultLine) this.selectguige(defaultLine.Id, defaultLine);
    },
    selectguige(id, item) {
      this.guige = id;
      console.log("选中线路:", item);
    },

    hightlight(str) {
      var search = "10M";
      var searchArray = ["20M", "10M", "专线", "纯净", "本土","高净值推流","可靠性","本地住宅IP（ISP）"];

      for (let i = 0; i < searchArray.length; i++) {
        if (str.includes(searchArray[i])) {
          search = searchArray[i];
        }
      }
      var subStr = new RegExp(search); // 匹配关键字正则
      let replaceString = '<span style="color:#e8a24a;font-weight:600">' + search + "</span>"; // 高亮替换v-html值
      let result = str.replace(subStr, replaceString);
      return result;
    },
    selectdy(index, item) {
      this.regionselect = index;
      this.regionitem = item;
      this.payform.IpRegionId = item.regionId;
      this.detailform.region = item.name2;
    },
    selectguige(index, item) {
      this.guige = index;
      this.guigeitem = item;
      this.payform.ProductId = item.Id;
      this.detailform.guige = item.Name;
      this.productDesc = item.Description.split('&');
      this.submitDisable = this.currentGuigeDisable

      if (this.buyTimeDisable(this.sc)) {
        // 如果当前类型不支持月付，则自动选择季付
        this.selectsc(90)
      }
    },
    selectsc (val) {
      if (this.buyTimeDisable(val)) {
        return
      }
      this.sc = val;
      this.payform.ChargeId = val;
      this.autoRenewChange()
      switch (val) {
        case 1:
          this.detailform.sc = "1天";
          this.discount = 1.0;
          break;
        case 7:
          this.detailform.sc = "7天";
          this.discount = 1.0;
          break;
        case 30:
          this.detailform.sc = "1月";
          this.discount = 1.0;
          break;
        case 90:
          this.detailform.sc = "1季度";
          this.discount = 0.97;
          break;
        case 180:
          this.detailform.sc = "半年";
          this.discount = 0.95;
          break;
        case 360:
          this.detailform.sc = "1年";
          this.discount = 0.9;
          break;

      }
    },
    selectnum(val) {
      if (!val) {
        this.payform.BuyCount = this.min;
        this.detailform.num = this.min;
        return
      }
      this.payform.BuyCount = val;
      this.detailform.num = val;
    },
    async navorderconfirm() {
      await this.getRegionStockList()
      if (this.subAccount) {
        this.$message.warning(
            "子账号暂不支持购买创建线路功能，请登录主账号进行创建~"
        );
        return;
      }
      if (!this.$store.state.user.authenticated) {
        this.$message.warning("未实名认证，无法进行此操作，可以点击头像中的个人中心进行认证.");
        return;
      }
      if (this.detailform.PayType == "amount" && this.balanceNotAvailable) {
        this.$message.warning("余额不足，请充值或更换其他支付方式");
        return;
      }
      if (this.guigeDisable(this.guige)){
        this.$message.warning("资源不足,请联系管理员添加资源.");
        return;
      }
      const h = this.$createElement;
      this.$confirm(
          h('p', null, [
            h('span', null, '请确认您选择的目标区域- '),
            h('span', {style: 'color: red;font-weight:bold;font-size:22px'}, this.detailform.region),
            h('span', null, ' , 是否继续?'),
          ]),
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      )
          .then(() => {
            if (!this.payform.AutoRenewSwitch) {
              this.payform.AutoRenew = 0
            }
            navigation.navigateTo({
              url: "lineorderconfirm",
              params: {
                payload: {
                  ...this.payform,
                },
                detail: {
                  ...this.detailform,
                },
              },
            });
          })
          .catch(() => {});
    },
    getUserBalance() {
      this.$store.dispatch("user/getuserwallet").then((res) => {
        // console.log("user wallet: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.balance = res.Balance / 100;
        }
      });
    },
    async getRegionStockList() {
        const res = await this.$store.dispatch("line/regionstocklist", {regionId: this.payform.IpRegionId})
          if (Object.keys(res).length !== 0) {
            Object.keys(this.productStockMap).forEach(key => {
              const stockInfo = this.productStockMap[key]
              const quantity = res[stockInfo.name]
              stockInfo.quantity = unknownQuantityLines.includes(key) && quantity ? 999 : quantity
              stockInfo.have = !!quantity
            })
            /*this.submitDisable = this.currentGuigeDisable*/
          }
    },
    autoRenewChange() {
      if (this.payform.ChargeId == 7 || this.payform.ChargeId == 10001 || this.payform.ChargeId == 10002) {
        this.payform.AutoRenew = 30
      } else {
        this.payform.AutoRenew = this.payform.ChargeId
      }
    },
    handleClick(tab, event) {
      if (tab.index == 1) {
        this.selectdy(this.SoutheastAsiaRegions[0].regionId, this.SoutheastAsiaRegions[0]);
      } else if (tab.index == 2) {
        this.selectdy(this.AmericaRegions[0].regionId, this.AmericaRegions[0]);
      } else if (tab.index == 3) {
        this.selectdy(this.EuropeRegions[0].regionId, this.EuropeRegions[0]);
      } else if (tab.index == 4) {
        this.selectdy(this.MiddleEastRegions[0].regionId, this.MiddleEastRegions[0]);
      } else if (tab.index == 5) {
        this.selectdy(this.AfricaRegions[0].regionId, this.AfricaRegions[0]);
      } else {
        this.selectdy(this.ChinaRegions[0].regionId, this.ChinaRegions[0]);
      }
    },

    /**
     * 判断选择的线路类型是否可购买
     * @param guige 线路类型（规格）
     * @returns {boolean}
     */
    guigeDisable(guige) {
      const productInfo = this.productStockMap[guige]    
      return productInfo && !productInfo.have
    }
  },
  computed: {
    ...mapGetters(["subAccount"]),

    currentGuigeDisable() {
      return this.guigeDisable(this.guige)
    },

    // 根据选中分组过滤线路
    selectedLines() {
      const group = this.lineGroups.find(g => g.group === this.selectedGroup);
      return group ? group.list : [];
    },

    showNotes() {
      const regionId = this.payform.IpRegionId
      const showNotesRegions = ['us-ca', 'us-ws']
      return isZirconLine(this.guige) && showNotesRegions.includes(regionId)
    },

    lineNotes() {
      const regionId = this.payform.IpRegionId
      if (regionId === 'us-ca') {
        return '由于住宅IP的稀缺性，该地域表示美国西部地区，不局限于洛杉矶。'
      } else if (regionId === 'us-ws') {
        return '由于住宅IP的稀缺性，该地域表示美国东部地区，不局限于华盛顿特区。'
      } else {
        return ''
      }
    },

    productMaxQuantity() {
      const map = this.productStockMap[this.guige]
      return map ? map.quantity : 0
    }
  },
  activated(){
    this.getUserBalance();
  },
  mounted() {
    var hasToken = getToken();
    this.fetchAutoRenewSwitchStatus();
    this.$store
        .dispatch("line/getregionlist", {Authorization: hasToken})
        .then((res) => {
          // console.log("line get region: " + JSON.stringify(res))
          if (Object.keys(res).length !== 0) {
            this.Regions = res.IpRegions;
            res.IpRegions.length &&
            res.IpRegions.forEach((item, index) => {
              if (item.classification == "欧洲") {
                this.EuropeRegions.push(item);
              } else if (item.classification == "美洲") {
                this.AmericaRegions.push(item);
              } else if (item.classification == "亚太") {
                this.SoutheastAsiaRegions.push(item);
              } else if (item.classification == "中东") {
                this.MiddleEastRegions.push(item);
              } else if (item.classification == "非洲") {
                this.AfricaRegions.push(item);
              } else {
                this.ChinaRegions.push(item);
              }
            });
            this.selectdy(this.ChinaRegions[0].regionId, this.ChinaRegions[0]);
          }
        });
        
    //暂时只有一种类型，写为固定格式
    this.initData();

    this.$store.dispatch("pay/getextrachargetype", {ResourceType: "PATH"}).then((res) => {
      if (res.RetCode == 0) {
        // console.log(res)
        if (res.ChargeTypes && res.ChargeTypes.length) {
          res.ChargeTypes.map((c) => {
            this.buyTime.push({

              chargeId: c.ChargeId,
              text: c.ChargeName,
              discount: c.DiscountText
            })

          })
        }
      }
    })

    this.$store.dispatch("group/getgrouplist").then((res) => {
      if (res.RetCode == 0) {
        res.Groups.length &&
        res.Groups.forEach((item, index) => {
          let data = {
            label: item.Name,
            value: item.Id,
          };
          this.options.push(data);
        });
        this.payform.GroupId = this.options.length ? this.options[0].value : "";
        console.log(this.payform.GroupId);
      }
    });
    this.getUserBalance();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
