<template>
  <div class="router-list-wrap">
    <div class="bread-wrap">
      <span class="bread-title">直播快</span>
    </div>
    <div class="content">
      <div class="list-top">
        <el-button type="primary" class="applyRouter" @click="applyRouter"
          >申请路由器</el-button
        >
        <a
          class="docDownBtn"
          href="https://pathlive.cn-bj.ufileos.com/%E7%9B%B4%E6%92%AD%E5%BF%AB%E8%B7%AF%E7%94%B1%E5%99%A8%E9%85%8D%E7%BD%AE%E5%90%91%E5%AF%BC.pdf"
          download="直播快路由器配置向导"
          >直播快路由器配置说明文档（点击下载）</a
        >

        <span class="searchBlock">
          <el-input
            v-model="searchKey"
            clearable
            @clear="listRouter"
            @keyup.enter.native="searchRouter"
            size="small"
            placeholder="支持按名称、ID、IP和 Mac 地址模糊搜索"
          ></el-input>
          <el-button size="small" type="primary" @click="searchRouter"
            >搜索</el-button
          >
        </span>
      </div>
      <com-table
        class="router-list"
        :headercellstyle="headerCellStyle"
        :columns="showTableColumn"
        :data="showRouters"
        :rowkey="(tabledata) => tabledata.rowIndex"
        tableheight="calc(100vh - 300px)"
        :pagination="pagination"
        @cellmouseenter="(row) => hoverCell(row, true)"
        @cellmouseleave="(row) => hoverCell(row, false)"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          label="操作"
          min-width="260"
        >
          <template slot-scope="scope">
            <el-tag
              v-loading="scope.row.onlineLoading"
              class="online"
              size="mini"
              :type="scope.row.online ? 'success' : 'danger'"
              @click="testRouterOnlineStatu(scope.row)"
            >
              {{ scope.row.online ? "在线" : "离线" }}
            </el-tag>
            <el-dropdown>
              <el-button
                :loading="scope.row.shouldLoading"
                class="sharebtn"
                size="mini"
                type="primary"
                >{{ scope.row.lineOperationName || "线路操作" }}</el-button
              >
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-if="!subAccount"
                    :disabled="scope.row.NotAvailable"
                    class="opBtn"
                    size="mini"
                    type="primary"
                    @click.native="bind(scope)"
                    >绑定</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="!subAccount"
                    :disabled="!scope.row.LineId"
                    class="opBtn"
                    size="mini"
                    type="primary"
                    @click.native="unbind(scope)"
                    >解绑</el-dropdown-item
                  >
                  <el-dropdown-item
                    :disabled="!scope.row.LineId"
                    class="opBtn"
                    size="mini"
                    type="primary"
                    @click.native="reconnect(scope)"
                    >重连</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              class="opBtn"
              size="mini"
              type="primary"
              :disabled="scope.row.NotAvailable"
              @click.native="openWiFiSettingPanel(scope)"
              >修改 WiFi 配置</el-button
            >
          </template>
        </el-table-column>
      </com-table>
    </div>

    <wifi-set
      ref="wifiModel"
      :show="models.wifi.show"
      :wifi-info="models.wifi.WiFiInfo"
      @close="models.wifi.show = !models.wifi.show"
      @wifiChange="setWiFi"
    >
    </wifi-set>

    <bind-line
      ref="binLineModel"
      :show="models.bind.show"
      :router-id="models.bind.routerId"
      :line-id="models.bind.lineId"
      @close="models.bind.show = !models.bind.show"
      @chooseLine="setLine"
    ></bind-line>

    <router-updater
      ref="routerUpdater"
      :show="models.updater.show"
      :name="models.updater.routerName"
      @close="models.updater.show = !models.updater.show"
      @change="routerUpdate"
    ></router-updater>
  </div>
</template>

<script>
import ComTable from "@/components/Table";
import { HEADER_CELL_STYLE, TABLE_COLUMNS, toRouterVo } from "./Constants.js";
import { deliveryOption } from "./DeliveryMixins";
import WifiSet from "@/views/lines/Router/Models/WifiSet.vue";
import navigation from "@/utils/navigation.js";
import BindLine from "@/views/lines/Router/Models/BindLine.vue";
import { mapGetters } from "vuex";
import { h } from "vue";
import RouterUpdater from "@/views/lines/Router/Models/RouterUpdater.vue";

export default {
  name: "soft-router",
  components: {
    RouterUpdater,
    BindLine,
    WifiSet,
    ComTable,
  },
  mixins: [deliveryOption],
  computed: {
    ...mapGetters(["subAccount"]),
  },
  watch: {
    "$route.params.payload": {
      handler: function (payload) {
        if (payload && payload.listRouter) {
          // 支付成功后，可能没那么快创建记录，所以需要稍等一会再 list 才能拿到最新的
          setTimeout(() => {
            this.listRouter();
          }, 3000);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      showTableColumn: TABLE_COLUMNS,
      headerCellStyle: HEADER_CELL_STYLE,
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      nameSortOrder: null, // 用于记录名字排序顺序，null表示无排序，'asc'表示升序，'desc'表示降序
      searchKey: "",
      routerList: [],
      showRouters: [],
      models: {
        wifi: {
          show: false,
          WiFiInfo: {},
        },
        bind: {
          show: false,
          routerId: "",
          lineId: "",
        },
        updater: {
          show: false,
          routerName: "",
          routerId: "",
        },
      },
      routerStatuFetchEnable: true,
      // 存放每个路由器的状态轮训定时器
      routerIntervals: {},
    };
  },
  methods: {
    routerStatus(row) {
      return {
        available: row.Available,
        notAvailable: row.Available,
        bound: !!row.LineId,
      };
    },

    applyRouter() {
      navigation.navigateTo({
        url: "router_apply",
      });
    },

    searchRouter() {
      const key = this.searchKey.trim().toLowerCase();
      this.showRouters = this.routerList.filter(
        (r) =>
          r.MacAddr.toLowerCase().includes(key) ||
          r.RouterId.toLowerCase().includes(key) ||
          r.RouterName.toLowerCase().includes(key) ||
          r.LineId.toLowerCase().includes(key) ||
          r.LineIp.includes(key)
      );
    },

    bind({ row }) {
      this.models.bind.routerId = row.RouterId;
      this.models.bind.lineId = row.LineId;
      this.models.bind.show = true;
    },

    unbind({ row }) {
      if (!row.LineId) {
        this.$message.warning(`该路由器未绑定线路`);
        return;
      }
      this.$set(row, "shouldLoading", true);
      this.$set(row, "lineOperationName", "解绑中");
      this.$store
        .dispatch("routers/unbindFromLine", { RouterId: row.RouterId })
        .then((res) => {
          this.$message.success("解绑成功！");
          // 重新查询所有路由器
          this.listRouter();
        })
        .catch((err) => {
          this.$message.error(`解绑失败：${err}`);
        })
        .finally(() => {
          this.$set(row, "shouldLoading", false);
          this.$set(row, "lineOperationName", "");
        });
    },

    reconnect({ row }) {
      if (!row.LineId) {
        this.$message.warning("该路由器还未绑定线路！");
        return;
      }
      this.$set(row, "shouldLoading", true);
      this.$set(row, "lineOperationName", "重连中");
      this.$store
        .dispatch("routers/reconnect", { RouterId: row.RouterId })
        .then((res) => {
          this.$message.success("重新连接成功");
        })
        .catch((err) => {
          this.$message.error("重新连接失败：" + err);
        })
        .finally(() => {
          this.$set(row, "shouldLoading", false);
          this.$set(row, "lineOperationName", "");
        });
    },

    setWiFi(params) {
      this.$store
        .dispatch("routers/wifiModify", params)
        .then((res) => {
          this.$message.success("WiFi 修改成功！");
          this.listRouter();
        })
        .catch((err) => {
          this.$message.error("修改 wifi 失败， " + err);
        })
        .finally(() => {
          this.$refs.wifiModel.close();
        });
    },

    setLine(params) {
      this.$store
        .dispatch("routers/bindToLine", params)
        .then((res) => {
          this.$message.success("绑定成功！");
          this.$refs.binLineModel.close();
          // 重新查询所有路由器
          this.listRouter();
        })
        .catch((err) => {
          let errMsg = `绑定到线路失败： ${err}`;
          if (errMsg.indexOf("status code 504") != -1) {
            errMsg = "绑定操作超时，请稍后刷新页面确认绑定结果";
            this.$message.info(errMsg);
          } else {
            this.$message.error(errMsg);
          }
          this.$refs.binLineModel.loadingDone(errMsg);
        });
    },

    routerUpdate({ name }) {
      this.$store
        .dispatch("routers/routerUpdate", {
          name,
          routerId: this.models.updater.routerId,
        })
        .then((res) => {
          this.$message.success("更新成功！");
          this.$refs.routerUpdater.close();
          // 重新查询所有路由器
          this.listRouter();
        })
        .catch((err) => {
          const errMsg = `更新失败： ${err}`;
          this.$refs.routerUpdater.loadingDone(errMsg);
          this.$message.error(errMsg);
        });
    },

    openWiFiSettingPanel({ row }) {
      this.models.wifi.show = !this.models.wifi.show;
      this.models.wifi.WiFiInfo = {
        domestic: row["DomesticWiFi"],
        fast: row["FastWiFi"],
        RouterId: row.RouterId,
      };
    },

    listRouter() {
      this.$store
        .dispatch("routers/listRouters")
        .then((retData) => {
          this.routerList = retData.routers.map(toRouterVo);
          this.routerList.forEach((router) => {
            this.testRouterOnlineStatu(router);
            if (!this.routerIntervals[router.routerId]) {
              this.routerIntervals[router.routerId] = setInterval(
                () => this.testRouterOnlineStatu(router),
                30 * 1000
              );
            }
          });
          this.mergeDelivery();
          this.showRouters = this.routerList;
        })
        .catch((err) => {
          this.$message.error(`获取路由列表失败： ${err}`);
        });
    },

    sortByName(order) {
      this.nameSortOrder = order;
      if (order === "asc") {
        this.showRouters.sort((a, b) => {
          return a.RouterName.localeCompare(b.RouterName);
        });
      } else if (order === "desc") {
        this.showRouters.sort((a, b) => {
          return b.RouterName.localeCompare(a.RouterName);
        });
      } else {
        // 这里可以选择重新获取原始数据，保持和现有逻辑一致
        this.listRouter();
      }
    },

    async testRouterOnline(routerId) {
      const res = await this.$store
        .dispatch("routers/routerOnline", routerId)
        .catch(() => {});
      return res || {};
    },

    testRouterOnlineStatu(router) {
      if (!this.routerStatuFetchEnable || router.onlineLoading) {
        return;
      }
      this.$set(router, "onlineLoading", true);
      this.testRouterOnline(router.routerId)
        .then((res) => {
          router.online = !!res.online;
          router.connected = !!res.connected;
        })
        .finally(() => this.$set(router, "onlineLoading", false));
    },

    hoverCell(row, show = false) {
      this.$set(row, "isShowBtn", show);
    },

    routerNameEditable() {
      const def = TABLE_COLUMNS.find((def) => def.prop === "RouterName");
      if (def) {
        def.sortable = true;
        def.sortIcon = {
          up: "icon-up",
          down: "icon-down",
          none: "icon-none",
        };
        def.formatter = (row, column, cellValue, index) => {
          let a = {};
          if (row.isShowBtn) {
            a = {
              class: "icon-edit-name iconfont icon-bianji",
              style: { "margin-left": "6px", cursor: "pointer" },
              on: {
                click: () => {
                  this.models.updater.routerId = row.RouterId;
                  this.models.updater.routerName = row.RouterName;
                  this.models.updater.show = true;
                },
              },
            };
          }
          // 添加排序图标点击事件
          const sortIcon = column.sortIcon;
          const currentSortOrder = this.nameSortOrder;
          if (sortIcon) {
            if (currentSortOrder === null) {
              a = {
                ...a,
                class: `${a.class} ${sortIcon.none}`,
              };
            } else if (currentSortOrder === "asc") {
              a = {
                ...a,
                class: `${a.class} ${sortIcon.down}`,
              };
            } else if (currentSortOrder === "desc") {
              a = {
                ...a,
                class: `${a.class} ${sortIcon.up}`,
              };
            }
          }
          return h("div", [cellValue, h("span", a)]);
        };
      }
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
  mounted() {
    this.listRouter();
    document.addEventListener("visibilitychange", () => {
      const state = document.visibilityState;
      this.routerStatuFetchEnable = state === "visible";
    });

    this.getuserinfo();
  },
  beforeRouteLeave(to, from, next) {
    this.routerStatuFetchEnable = false;
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routerStatuFetchEnable = true;
    });
  },
  async created() {
    this.routerNameEditable();
    await this.queryDelivery();
  },
  beforeDestroy() {
    Object.values(this.routerIntervals).forEach((internal) =>
      clearInterval(internal)
    );
  },
};
</script>

<style scoped>
@import "./index.scss";

::v-deep .opBtn,
.el-dropdown-menu__item {
  min-width: 40px;
  text-align: center;
}
</style>
